import React, { useState } from 'react'
import web from '../Images/ServicePage/Artboard 1 copy 13@2x 1.svg';
import { NavLink, Link, useLocation } from 'react-router-dom';
import GetAQuote from './GetAQuote';
import "./style.css";

export default function Navbar() {

    const location = useLocation();

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ minHeight: "100px" }}>
                <div className="container mx-auto">
                    <NavLink activeClassName="menu_active" exact className="navbar-brand" to="/">
                        <img className="artboard-1-copy-132x-1"
                            src={web}
                            alt=""
                            style={{ height: "130px", width: "160px" }}
                        />
                    </NavLink>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-5">

                            <NavLink exact
                                className="nav-link place valign-text-middle raleway-bold-eerie-black-18px"
                                aria-current="page" to="/">Home</NavLink>

                            <NavLink exact
                                className="nav-link valign-text-middle raleway-bold-eerie-black-18px"
                                aria-current="page" to="/portfolio">Portfolio</NavLink>
                                
                            <NavLink exact
                                className="nav-link valign-text-middle raleway-bold-eerie-black-18px"
                                aria-current="page" to="/products">Products</NavLink>

                            <NavLink exact
                                className="nav-link valign-text-middle raleway-bold-eerie-black-18px"
                                aria-current="page" to="/services">Services</NavLink>

                            <NavLink
                                className="nav-link valign-text-middle raleway-bold-eerie-black-18px"
                                aria-current="page" to="/blogs">Blogs</NavLink>

                            <li className="get-a-quote valign-text-middle raleway-bold-eerie-black-18px ">
                                {
                                    location.pathname === '' ? (<Link to="/#contact">Get a Quote</Link>)
                                    :(
                                        <div style={{cursor: 'pointer'}} onClick={ () => setOpen(true) }>Get a Quote</div>
                                    )
                                }
                                { open && (<GetAQuote handleClose={handleClose}/>) }
                            </li>

                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
};