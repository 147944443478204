import { Link } from 'react-router-dom';
import { blogs } from './blogs';

export default function Summary(props) {

  return (
    <div className='container'>
    {
      blogs.map(blog => (
          <article className="post with_border rounded bottommargin_40 pointer">
            <div className="row">
                <div className="col-md-3 margin_auto">
                    <div> 
                      <Link to={blog.url_endpoint}>
                        <img style={{border: "1px solid #e7eaeb", width: "100%"}} className="blog-img" src={blog.img_path} alt="" /> 
                      </Link>
                    </div>
                </div>
                <div className="col-md-9" style={{display: 'flex'}}>
                    <div className="item-content">
                        <div>
                          <h4 className="entry-title entry-title2"> <Link className="logo-color1" to={blog.url_endpoint}>{blog.title}</Link> </h4>
                          <p className='content'>{blog.intro_para} <Link className="link logo-color2" to={blog.url_endpoint}>Read More</Link></p>
                        </div>
                        <div><i className="logo-color2">{blog.date_published}</i><i style={{right: "35px", position:"absolute"}} className="logo-color2">{blog.author}</i></div>
                    </div>
                </div>	
            </div>
          </article>
      ))
    }
    </div>
  )
}