import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: "#1a1a1a",minHeight:"auto",width:"100%" ,paddingBottom:"50px",overflow:"hidden"}}>

                <div className="container-fluid px-4" style={{paddingTop:"70px"}}>

                    <div className="row justify-content-center" >

                        <div className="col-md-3 gx-5 gy-5">
                            <div className="section-1" >
                                <h3 className="mb-3" style={{fontSize: "25px",color:"white"}}>Office</h3>
                                <p className="text-muted">
                                    
                                <i class="far fa-address-book" style={{color:"white"}}> </i> 1752P, Sector-14, Hisar - 125001 (Haryana)</p>
                            </div>
                        </div>

                        <div className="col-md-3 gx-5 gy-5">
                            <div className="section-2">
                                <h3 className="mb-3" style={{fontSize: "25px",color:"white"}}>Start a conversation</h3>

                                <div className="text-muted">
                                    <a href="tel:+918448147907" style={{ fontSize: "14px", color: "#748182", textDecoration: "none" }}> <i className="fas fa-x fa-phone mt-2" style={{ color: "white" }}></i> +91 84481 47907 /</a>
                                    <a href="tel:+919711620040" style={{ fontSize: "14px", color: "#748182", textDecoration: "none" }}> +91 97116 20040</a>
                                </div>

                                <div className="email mt-2">
                                    <i style={{ color: "white",marginRight:"5px" }} className="fas fa-envelope" ></i>
                                    <a className=" text-muted" href="mailto:thecrewroom@rubistosdesigns.com" style={{ textDecoration: "none" }}>thecrewroom@rubistosdesigns.com</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 gx-5 gy-5 ">
                            <div className="section-3">
                                <h3 className="mb-3" style={{fontSize: "25px",color:"white"}}>Social</h3>
                                <div className="handle " style={{marginLeft:"-17px"}}>
                                    <a style={{color:"white"}} href="https://www.instagram.com/the.crew.room/" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-instagram px-3"></i></a>
                                    <a style={{color:"white"}} href="https://www.facebook.com/The-Crew-Room-110018461258543" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-facebook px-3"></i></a>
                                    <a style={{color:"white"}} href="https://www.linkedin.com/company/thecrewroom/" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-linkedin-in px-3"></i></a>
                                    <a style={{color:"white"}} href="https://in.pinterest.com/thecrewroom/" target="_blank" rel="noopener noreferrer"><i className="fab fa-2x fa-pinterest px-3"></i></a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="text-center" style={{paddingTop:"70px",color:"white"}}>
                        <h4>Parented Under:</h4>
                        <h4>Rubistos Designs Private Limited</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
