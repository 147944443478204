import { Route, Switch, Redirect } from 'react-router-dom'
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/react";
import React, { useState, useEffect } from 'react'
import Service from './Components/Service/index.jsx'
import Home from "./Components/Home/index"
import Product from "./Components/Product/index.jsx";
import Portfoilo from './Components/Portfolio/index.jsx';
import Blogs from './Components/Blogs/index';
import Brand from './Components/Blogs/Brand';

function App(props) {

  const override = css`
  display: block;
  margin: 350px  auto;
  border-color: red;
`;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500)
  }, [])

  return (
    <>
      <div className="App">
        {loading ?

          <HashLoader color={"#123abc"} loading={loading} size={50} css={override} /> :

          <Switch>

            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Service} />
            <Route exact path="/products" component={Product} />
            <Route exact path="/portfolio" component={Portfoilo} />
            <Route path="/blogs/branding" component={Brand} />
            <Route exact path="/blogs" component={Blogs}/>

            {props.children}
            <Redirect to="/" />
          </Switch>
        }

      </div>
    </>
  );
}

export default App;
