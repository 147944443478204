import React ,{Suspense,lazy} from 'react'
import Navbar from '../Shared/Navbar';

const Header = lazy(()=>import ("../Shared/Header"))
const Corporate = lazy(()=>import ("./Corporate"))
const Cardinal = lazy(()=>import ("./Cardinal"))
const Package = lazy(()=>import ("./Package"))
const Footer = lazy(()=>import ("../Shared/Footer"))

const ServicePage = (props) => {
    return (
        <>
            <Navbar/>
            <Suspense  fallback={<div>...</div>}> <Header heading="OUR PRODUCTS"/></Suspense> 
            <Suspense  fallback={<div>...</div>}> <Corporate/></Suspense> 
            <Suspense  fallback={<div>...</div>}> <Package/></Suspense> 
            <Suspense  fallback={<div>...</div>}> <Cardinal/></Suspense> 
            <Suspense  fallback={<div>...</div>}> <Footer/></Suspense>
        </>
    )
}

export default ServicePage;
