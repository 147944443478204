import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export default function CustomizedDialogs({handleClose}) {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
            "form-name": "contact", name,
            email,
            phone,
            subject,
            message
        })
    })
    .then(() => {
        alert("I'll get back to you ASAP!");

        setName("");
        setEmail("");
        setMobile("");
        setSubject("");
        setMessage("");

        handleClose();
    })
    .catch(() => {
        alert('Error');
    })
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" style={{textAlign:"center"}} onClose={handleClose}>
          Get a Quote
        </DialogTitle>
        <DialogContent dividers>
        <div className="cform needs-validation" id="contact-form" >
          <form name="contact" onSubmit={(e) => handleSubmit(e)} data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <div className="field your-name form-group">
                <input type="text" placeholder="Your Name" name="name" className="cform-text form-control " autoComplete="off" size="40" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div className="field your-email form-group">
                <input type="email" name="email" placeholder="Your Email" className="cform-text form-control " autoComplete="off" size="40" value={email} onChange={(e) => setEmail(e.target.value)} required/>
            </div>
            <div className="field your-mobileNo form-group">
                <input type="phone" name="phone" placeholder="Your Mobile Number" className="cform-text form-control " autoComplete="off" size="40" value={phone} onChange={(e) => setMobile(e.target.value)} required minLength="10"/>
            </div>
            <div className="field subject form-group">
                <input type="text" name="subject" placeholder="Subject" className="cform-text form-control " autoComplete="off" size="40" value={subject} onChange={(e) => setSubject(e.target.value)} required/>
            </div>
            <div className="field message form-group">
                <textarea name="message" className="cform-text form-control area" cols="50" rows="4" autoComplete="off" placeholder="Write your message here!!" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
            </div>
            <div className="text-center">
                <button type="submit" style={{marginTop:"30px"}} className="btn btn-dark btn-get-started1" >Get a Quote</button>
            </div>
          </form>
        </div>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}