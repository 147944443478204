import React from 'react'
import './style.css';
import p1 from '../Images/Portfolio/DSC_0102 (1).jpg';
import p2 from '../Images/Portfolio/DSCN6124 (1).jpg';
import p6 from '../Images/Portfolio/DSCN6141_1 (1).jpg';
import p4 from '../Images/Portfolio/DSCN6151 (1).jpg';
import p5 from '../Images/Portfolio/10 (1).jpg';
import p3 from '../Images/Portfolio/11 (1).jpg';
import p7 from '../Images/Portfolio/12 (1).jpg';
import p8 from '../Images/Portfolio/2 (1).jpg';
import p9 from '../Images/Portfolio/3 (2) (1).jpg';
import p10 from '../Images/Portfolio/8 (1).jpg';
import p11 from '../Images/Portfolio/DSCN6146_1 (2) (1).jpg';
import p12 from '../Images/Portfolio/DSCN6159 (1).jpg';
import p13 from '../Images/Portfolio/DSCN6134 (1).jpg';
import p14 from '../Images/Portfolio/7 (1).jpg';
import p15 from '../Images/Portfolio/9 (1).jpg';
import p16 from '../Images/Portfolio/13 (1).jpg';
import p17 from "../Images/Portfolio/Metvy Hybrid Working.jpg"
import p18 from "../Images/Portfolio/EverythingSocial.jpg"
import p19 from "../Images/Portfolio/Hiring Poster.jpg"
import p20 from "../Images/Portfolio/Aqua EL.jpg"
import p21 from "../Images/Portfolio/Arihant5.jpg"
import p22 from "../Images/Portfolio/ES Branding & Creative Consultancy.jpg"
import p23 from "../Images/Portfolio/ES Influencer Marketing Management.jpg"
import p24 from '../Images/Portfolio/ES Social Media Marketing Management.jpg'
import p25 from "../Images/Portfolio/ES Website Development & Digital Marketing (1).jpg"
import p26 from "../Images/Portfolio/Arihant3.jpg"





function ProductPage() {
    return (
        <>
            <div className="main-container1 rellax" data-rellax-speed="10" data-rellax-xs-speed="10" data-rellax-mobile-speed="10" data-rellax-tablet-speed="10" data-rellax-desktop-speed="10" style={{ paddingBottom: "100px", paddingTop: "100px" }}>


                <div className="container">
                <h1 className="text-16 text-center" style={{ marginBottom: "10px", fontFamily: "Bebas Neue", fontSize: "360%" }}  data-aos="fade-up">
                    OUR Portfolio
                </h1>
                    <div className="product" style={{marginTop:"-10px"}}>
                        <div className="row mx-auto" >

                            <div className="column" >
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p1} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p2} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p3} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p12} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p17} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p13} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p19} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p22} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p23} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                               


                            </div>
                            <div className="column" md={3}>
                            <div id="zoom-out">
                                    <figure>
                                        <img src={p16} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p20} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p4} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p5} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p6} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p10} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p14} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p24} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p25} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                            </div>
                            <div className="column" md={3}>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p7} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p8} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p9} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p11} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p15} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p18} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p21} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>
                                <div id="zoom-out">
                                    <figure>
                                        <img src={p26} className="common" style={{ width: "100%" }} alt="" data-aos="fade-in" />
                                    </figure>
                                </div>

                            </div>
                        </div>

                       

                    </div>
                </div>



            </div>

        </>
    )
}

export default ProductPage;
