import React ,{Suspense,lazy} from 'react'
import Navbar from '../Shared/Navbar'
const WhyUs = lazy(()=>import ("./Whyus"))
const Header = lazy(()=>import ("../Shared/Header"))
const CreativeOurService = lazy(()=>import ("./OurService"))
const Footer = lazy(()=>import ("../Shared/Footer"))

const ServicePage = (props) => {
    return (
        <>
            <Navbar/>
            <Suspense fallback={<div>...</div>}><Header heading="OUR SERVICES"/></Suspense> 
            <Suspense fallback={<div>...</div>}><CreativeOurService/></Suspense>  
            <Suspense fallback={<div>...</div>}><WhyUs/></Suspense> 
            <Suspense fallback={<div>...</div>}><Footer/></Suspense>
        </>
    )
}

export default ServicePage;
