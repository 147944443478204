import React from 'react'
import Footer from '../Shared/Footer'
import Navbar from '../Shared/Navbar'
import ProductPage from './Product'

const index = () => {
    return (
        <>
            <Navbar/>
            <ProductPage/>
            <Footer/>
        </>
    )
}

export default index
