import React, {Suspense,lazy} from 'react';
import Navbar from '../Shared/Navbar';
import './styles.css';
import Summary from './Summary';

const Header = lazy(()=>import ("../Shared/Header"))
const Footer = lazy(()=>import ("../Shared/Footer"))

const BlogsPage = (props) => {
    return (
        <>
            <Navbar/>
            <Suspense fallback={<div>...</div>}><Header heading="BLOGS"/></Suspense> 
            <Summary />
            <Suspense  fallback={<div>...</div>}> <Footer/></Suspense>
        </>
    )
}

export default BlogsPage;
