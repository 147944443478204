import React , {Suspense,lazy} from 'react'
const TopPage = lazy(()=>import ("./TopPage"))
const Service = lazy(()=>import ("./Service"))
const Cards = lazy(()=>import ("./Cards"))
const MeetOurTeam = lazy(()=>import ("./MeetOurTeam"))
const OurVision = lazy(()=>import ("./VisionPage"))
const OurClients = lazy(()=>import ("./OurClients"))
const ProductPage = lazy(()=>import ("./ProductPage"))
const Contact = lazy(()=>import ("../Shared/Contact"))
const Footer = lazy(()=>import ("./Footer"))

const HomePage = () => {
    return (
        <>
            <Suspense fallback={<div>...</div>} ><TopPage /> </Suspense>
            <Suspense fallback={<div>...</div>} ><Service/> </Suspense>
            <Suspense fallback={<div>...</div>} ><Cards/> </Suspense>  
            <Suspense fallback={<div>...</div>} ><MeetOurTeam/> </Suspense> 
            <Suspense fallback={<div>...</div>} ><OurVision/> </Suspense>  
            <Suspense fallback={<div>...</div>} ><OurClients/> </Suspense> 
            <Suspense fallback={<div>...</div>} ><ProductPage/> </Suspense> 
            <Suspense fallback={<div>...</div>} ><Contact/> </Suspense>  
            <Suspense fallback={<div>...</div>} ><Footer/>  </Suspense>  
        </>
    )
}

export default HomePage
