import React, {Suspense,lazy} from 'react';
import Navbar from '../Shared/Navbar';
import './styles.css';

const Footer = lazy(()=>import ("../Shared/Footer"))

const BlogsPage = () => {
    return (
        <>
            <Navbar/>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <article className="single-post vertical-item content-padding big-padding with_border rounded post">
                      <div className="top_rounded">
                        <img src='/images/tcr-branding.jpg' alt="brand" style={{width: '100%', borderBottom: "2px solid #e7eaeb"}} />
                      </div>
                      <div className="content-box">
                          <header className="entry-header">
                              <p className="logo-color2 bold-text" style={{float: "left"}}>February 12 2022</p>
                              <p style={{float: "right"}} className="logo-color2 bold-text">Sahitya Bishnoi</p>
                              <br />
                              <h3 className="entry-title">WHAT IS A BRAND?</h3>
                          </header>
                          <div>
                            <p className='roboto-normal-masala-18px'>Your Brand is what people say about you when you are not in the room. You can consider a brand as the idea or image people have in mind when thinking about specific products, services, and activities of a company, both in a practical (e.g. “the shoe is light-weight”) and emotional way (e.g. “the shoe makes me feel powerful”). It is therefore not just the physical features that create a brand but also the feelings that consumers develop towards the company or its product.</p>
                            <p className='roboto-normal-masala-18px'>A product can be easily copied by other players in a market, but a brand will always be unique. <span className="bold-text">Pepsi and Coca-Cola taste very similar, however for some reason, some people feel more connected to Coca-Cola, others to Pepsi.</span> The product sold is similar, but in order to convince people to purchase a particular product, companies develop different brands, and each one of these brands provides a different meaning to the product. Differentiation can relate to any combination of the following, ranging from the physical characteristics of your product or service, to the emotional response your brand triggers, aspects of its presentation, its price point such as very high or very low, your brand story, and even the customer experience. </p>
                          </div>
                          <div>
                            <h4 className="entry-title">WHAT IS BRANDING?</h4>
                            <p>Branding is the process of giving a story about a specific organization, company, products or services by creating and shaping a brand in consumers’ minds.   It is a strategy that helps you to make people quickly identify and experience your brand and give them a reason to choose your product over competition’s. <span className="bold-text">Branding clarifies WHAT YOUR BRAND IS AND IS NOT.</span></p>
                            <h5 className='entry-title'>How Can It be done?</h5>
                            <ul>
                              <li className='bullet-points'><b>Determine your target audience</b></li>
                              <p><span className='bold-text'>Determine who is most likely to buy the product.</span> Consider several factors like age, gender, location and income to create a demographic.</p>
                              <p><span className='bold-text'>Talk to your target market.</span> Consider engaging with people who fit your company’s ideal client profile to determine what their likes and needs are, as well as what brands appeal to them and why.</p>
                              <li className='bullet-points'><b>Position your product and business</b></li>
                              <p><span className='bold-text'>Positioning a business involves deciding how to distinguish its products or services from other similar offerings in the marketplace.</span> To do so, you first need to gather as much information as you can about your company’s direct competition, such as details about their products, prices and markets, as well as their marketing strategies. Try to determine possible shortcomings in their products, services or areas in the market that they are not satisfying, and use this information to your advantage.</p>
                              <p>After you have investigated your competition, you should <span className='bold-text'>develop a unique selling proposition.</span> A USP is a concise statement that tells clients what the company is offering. Your USP should highlight the features of your product that make it unique and add value for clients. </p>
                              <li className='bullet-points'><b>Define your company’s personality</b></li>
                              <p>To a large extent, <span className='bold-text'>A brand conveys the business’ identity.</span> An important step in building a brand, then, involves determining the company’s personality.</p>
                              <p>Deciding on your company’s personality will require <span className='bold-text'>creative brainstorming</span> with other members of the branding team. You can start the process by thinking of the company as a person. For instance, if the company is a backpacker, you could describe what this person looks like and how they act. The use of descriptive words such as “traveler,” “independent,” “revolutionary” or “fun” will help you and the branding team to verbalize your abstract thoughts.</p>
                              <li className='bullet-points'><b>Choose a logo and slogan</b></li>
                              <p>An effective logo can make your brand visually appealing, while a successful slogan help customers remember your product.</p>
                              <p><span className='bold-text'>Logo:</span> A logo conveys the image and personality of a brand. You may want to consult with a professional designer or brand agency during this process to ensure that your logo is tasteful, effective and well-crafted. A professional designer will advise you on things like font, color, logo size, iconography and general design. They will also help you to <span className='bold-text'>design a logo that reflects or complements your brand name.</span></p>
                              <p><span className='bold-text'>Slogan: A slogan is a short, catchy phrase</span> that you can employ during marketing campaigns to <span className='bold-text'>give your brand an extra edge.</span> It’s not a permanent feature of your brand, so you can adjust and change it for new marketing campaigns. For example, if you sell snorkeling gear, your slogan could be, “Get the best view under the seas.”</p>
                            </ul>
                          </div>
                      </div>
                  </article>
                </div>
              </div>
            </div>
            <Suspense  fallback={<div>...</div>}> <Footer/></Suspense>
        </>
    )
}

export default BlogsPage;
